import React from 'react'
import screenENG from '../../assets/pictures/screenENG.png';
import screenPL from '../../assets/pictures/screenPL.png';
import LineLi from '../../assets/pictures/LineLi.svg';
import Li from '../../assets/pictures/Li.svg';
import { useTranslation } from 'react-i18next';
import { logos, lies } from './logos';
import style from './BlockSecond.module.css';

export const BlockSecond = () => {
  const { t, i18n } = useTranslation();
  const currentLng = i18n.language;
  return (
    <div id="Features" className={style.secondBlock}>
    <div className={style.container}>
        <div className={style.titleContainer}>
          <div><img src={LineLi} alt="li" /></div>
          <div className={style.title}>{t("SecondBlock.Create a digital copy")} <br/> {t("SecondBlock.Your business")}</div>
        </div>
        <div className={style.contentWrapper}>
           <div className={style.screensImage}>
              <div><img className={style.screensImg} src={currentLng === "ENG" ? screenENG : screenPL} alt=" " /></div>
           </div>
           <div className={style.ulBlock}>
              <div className={style.ulWrapper}>
                {
                  lies.map(e => (
                    <div  key={e.id} className={style.liWrapper}>
                      <div className={style.liImage}>
                        <img src={Li} alt=" " />
                      </div>
                      <div className={style.li}>
                        <div className={style.liTitle}>{t(`SecondBlock.${e.title}`)}</div>
                        <div className={style.liSubTitle}>{t(`SecondBlock.${e.subTitle}`)}</div>
                      </div>
                  </div>
                  ))
                }
              </div>
              <div className={style.companiesWrapper}>
              <div className={style.responsivecompaniesText}>
                  Reduce your expenses using these cards
                </div>
                <div className={style.companies}>
                  {
                    logos.map(e => <img className={style.companiesImg} key={e.id} src={e.logo} alt="logo"/>)
                  }
                </div>
                <div className={style.companiesText}>
                  {t("SecondBlock.Reduce your expenses using these cards")}
                </div>
              </div>
           </div>
        </div>
    </div>
  </div>

  )
}
