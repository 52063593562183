import TagManager from 'react-gtm-module'
import React from 'react';
import './i18n';
import LandingPage from './pages/LandingPage/LandingPage'

const tagManagerArgs = {
  gtmId: 'G-C9KT34GK9Z'
}
TagManager.initialize(tagManagerArgs)

const App = () => (<LandingPage />);

export default App;
