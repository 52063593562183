import logoAS24 from '../../assets/pictures/logoAS24.svg';
import logoBP from '../../assets/pictures/logoBP.svg';
import logoCircle from '../../assets/pictures/logoCircle.svg';
import logoDKV from '../../assets/pictures/logoDKV.svg';
import logoE100 from '../../assets/pictures/logoE100.svg';
import logoEW from '../../assets/pictures/logoEW.svg';
import logoMoya from '../../assets/pictures/logoMoya.svg';
import logoShell from '../../assets/pictures/logoShell.svg';
import logoUTA from '../../assets/pictures/logoUTA.svg';

export const logos = [
  {id: 1, logo: logoBP},
  {id: 2, logo: logoShell},
  {id: 3, logo: logoUTA},
  {id: 4, logo: logoE100},
  {id: 5, logo: logoCircle},
  {id: 6, logo: logoDKV},
  {id: 7, logo: logoAS24},
  {id: 8, logo: logoMoya},
  {id: 9, logo: logoEW},
]

export const lies = [
  {id: 1, title: "BI & Reporting", subTitle: 'Make easy and fast data driven decisions'},
  {id: 2, title: "Data Platform", subTitle: 'Operate with a single interface for all data sources'},
  {id: 3, title: "AI & Cloud", subTitle: 'Savings from AI tips and cloud usability'},
]