import React from 'react';
import LineEnd from '../../assets/pictures/LineEnd.svg';
import { Footer } from '../../components/Footer/Footer';
import { ResponsiveFooter } from '../../components/ResponsiveFooter/ResponsiveFooter';
import { useTranslation } from 'react-i18next';
import style from './BlockFifth.module.css';

export const BlockFifth = ({scroll, changeLanguage}) => {
  const { t } = useTranslation()
  return(
    <div id="ContactUs" className={style.fifthBlock}>
      <div className={style.container}>
        <div className={style.titleContainer}>
          <div><img src={LineEnd} alt="li" /></div>
          <div className={style.contactWrapper}>
            <div className={style.title}>{t("FifthBlock.Contact Us")}</div>
            <div className={style.inputWrapper}>
              <div className={style.inputName}>{t("FifthBlock.Name")}</div>
              <input className={style.input} type="text" placeholder={t("FifthBlock.Enter your name")} />
            </div>
            <div className={style.inputWrapper}>
              <div className={style.inputName}>{t("FifthBlock.Email")}</div>
              <input className={style.input} type="text" placeholder={t("FifthBlock.Enter your email")} />
            </div>
            <div className={style.inputWrapper}>
              <div className={style.inputName}>{t("FifthBlock.How we can help you?")}</div>
              <input className={style.input} type="text" placeholder={t("FifthBlock.Leave a message")} />
            </div>
            <button className={style.button}>{t("FifthBlock.Submit")}</button>
          </div>
        </div>
        <Footer scroll={scroll}/>
        <ResponsiveFooter scroll={scroll} changeLanguage={changeLanguage}/>
        <div className={style.rights}>2022 Fleet Insider. All rights reserved</div>
      </div>
    </div>
  )
}